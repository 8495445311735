<template>
  <div>
    <b-overlay :show="isLoading">
      <div v-if="deliveries">
        <b-row>
          <b-col>
            <b-button
              variant="primary"
              class="mb-2"
              @click="$router.go(-1)"
            >
              <FeatherIcon icon="ArrowLeftIcon" />
              Go back
            </b-button>
          </b-col>

          <b-col
            cols="12"
            lg="3"
          >
            <statistic-card-horizontal
              :statistic="deliveries.id"
              statistic-title="Delivery ID"
              icon="TruckIcon"
            />
          </b-col>

          <b-col
            cols="12"
            lg="3"
          >
            <statistic-card-horizontal
              :statistic="deliveries.orders.length"
              statistic-title="Order(s) Found"
              icon="PackageIcon"
              color="info"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
          >
            <b-card>
              <b-card-body>
                <b-row class="d-flex align-items-center">
                  <b-col
                    cols="12"
                    lg="4"
                    class="mb-2 text-center"
                  >
                    <b-img
                      :src="require('@/assets/images/logo/golog_smarttruck.png')"
                      width="300px"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="4"
                  >
                    <h4>
                      <strong>Company Name: </strong>
                      <span>{{ deliveries.smarttruck.driver ? deliveries.smarttruck.driver.company.name : '-' }}</span>
                    </h4>

                    <h4>
                      <strong>Smarttruck Driver: </strong>
                      <span>{{ deliveries.smarttruck.driver ? deliveries.smarttruck.driver.full_name : '-' }}</span>
                    </h4>

                    <h4>
                      <strong>Vehicle Plate #: </strong>
                      <span>{{ deliveries.smarttruck.vehicle ? deliveries.smarttruck.vehicle.plate : '-' }}</span>
                    </h4>

                    <h4>
                      <strong>Vehicle Model: </strong>
                      <span>{{ deliveries.smarttruck.vehicle ? deliveries.smarttruck.vehicle.model : '-' }}</span>
                    </h4>

                    <h4>
                      <strong>Vehicle Make: </strong>
                      <span>{{ deliveries.smarttruck.vehicle ? deliveries.smarttruck.vehicle.make : '-' }}</span>
                    </h4>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="4"
                    class="text-center"
                  >
                    <b-avatar
                      v-if="deliveries.smarttruck.vehicle"
                      :src="`${$s3URL}uploads/user/${deliveries.smarttruck.vehicle.image}`"
                      class="w-50 h-50"
                      rounded
                    />
                    <b-avatar
                      v-else
                      class="w-50 h-50"
                      rounded
                    />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col>
            <app-collapse>
              <app-collapse-item
                v-for="(order, index) in deliveries.orders"
                :key="index"
                :title="`Order ID: ${order.reference_id}`"
              >
                <b-row class="py-2 border-bottom">
                  <b-col cols="12">
                    <OrderDetails
                      :order="order"
                    />
                  </b-col>
                </b-row>

                <b-row class="py-4 border-bottom">
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <DeliveryOrigin
                      :order="order"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <DeliveryDestination
                      :order="order"
                    />
                  </b-col>
                </b-row>

                <b-row class="py-2">
                  <b-col cols="12">
                    <b-card>
                      <b-card-header>
                        <b-card-title class="text-uppercase">
                          <h1>
                            <strong>
                              <FeatherIcon
                                icon="PackageIcon"
                                size="30"
                              />
                              Product Details
                            </strong>
                          </h1>
                        </b-card-title>
                      </b-card-header>

                      <b-card-body class="p-0">
                        <MyTable
                          :rows="[order]"
                          :columns="columns"
                          :is-loading="isLoading"
                          :search-enabled="false"
                          :pagination-enabled="false"
                        >
                          <template v-slot="{props}">
                            <span v-if="props.column.field === 'category'">
                              {{ props.row.category.text }}
                            </span>

                            <span v-else-if="props.column.field === 'packaging'">
                              {{ props.row.packaging.text }}
                            </span>

                            <span v-else-if="props.column.field === 'size'">
                              {{ props.row.size }} {{ props.row.unit }}
                            </span>

                            <span v-else-if="props.column.field === 'expiry_date'">
                              {{ $helpers.formatDate(props.row.po_expiry_date) }}
                            </span>

                            <span v-else>
                              {{ props.formattedRow[props.column.field] }}
                            </span>
                          </template>
                        </MyTable>
                      </b-card-body>
                    </b-card>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
      </div>

      <template #overlay>
        <div class="text-center">
          <b-spinner
            variant="primary"
            class="mb-2"
          />
          <p id="cancel-label">
            Fetching data...
          </p>
        </div></template>
    </b-overlay>
  </div>
</template>

<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import MyTable from '@/components/MyTable.vue'
import { OrderDetails, DeliveryOrigin, DeliveryDestination } from '@/components'
import axios from '@axios'

export default {
  name: 'DeliverySummary',
  components: {
    StatisticCardHorizontal,
    AppCollapse,
    AppCollapseItem,
    MyTable,
    OrderDetails,
    DeliveryOrigin,
    DeliveryDestination,
  },
  data() {
    const columns = [
      {
        field: 'category',
        label: 'Category',
        sortable: false,
      },
      {
        field: 'quantity',
        label: 'Quantity',
        sortable: false,
      },
      {
        field: 'packaging',
        label: 'Packaging',
        sortable: false,
      },
      {
        field: 'size',
        label: 'Size',
        sortable: false,
      },
      {
        field: 'sku',
        label: 'SKU',
        sortable: false,
      },
      {
        field: 'temperature',
        label: 'Temperature',
        sortable: false,
      },
      {
        field: 'expiry_date',
        label: 'Expiry Date',
        sortable: false,
      },
      {
        field: 'remarks',
        label: 'Remarks',
        sortable: false,
      },
    ]

    return {
      deliveries: null,
      isLoading: false,
      columns,
    }
  },
  created() {
    this.fetchOrders(this.$route.params.deliveryId)
  },
  methods: {
    async fetchOrders(id) {
      this.isLoading = true
      const response = await axios.get(`deliveries/${id}`)
      this.deliveries = response.data
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
  .w-50 {
    width: 50%;
  }
  .h-50 {
    height: 50%;
  }
  .list-group .list-group-item:hover {
    background-color: #fff;
  }
</style>
